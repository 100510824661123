<template>
  <div>
    <div v-if="!auctionifos">
      <notfound :checkdata="initialLoading"></notfound>
    </div>
    <div v-else>
      <div class="sp-area">
        <div class="container-fluid">
          <vue-easy-lightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
            :loop="false"
            :moveDisabled="true"
          >
          </vue-easy-lightbox>
          <div class="sp-nav">
            <div style="text-align: right">
              <button
                class="btn btn-primary"
                style="margin-right: 35px; margin-top: 0px; margin-bottom: 13px"
                @click="goBack()"
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
              </button>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="sp-img_area" style="padding-left: 20px">
                  <div>
                    <hooper group="group1">
                      <slide
                        v-for="(vehImage, index) in vehImageArry"
                        :key="index"
                      >
                        <button @click="showMultiple(vehImageArry, index)">
                          <img
                            v-if="vehImage.imageurl != null"
                            :src="path + vehImage.imageurl"
                            alt="Product Image"
                          />
                        </button>
                      </slide>
                    </hooper>
                    <hooper group="group1" :itemsToShow="4" :centerMode="false">
                      <slide
                        v-for="(vehImage, index) in vehImageArry"
                        :key="index"
                      >
                        <div class="thumbnaildetails">
                          <button @click="showMultiple(vehImageArry, index)">
                            <img
                              v-if="vehImage.imageurl != null"
                              :src="path + vehImage.imageurl"
                              alt="Product Image"
                            />
                          </button>
                        </div>
                      </slide>
                      <hooper-navigation
                        slot="hooper-addons"
                      ></hooper-navigation>
                      <hooper-pagination
                        slot="hooper-addons"
                      ></hooper-pagination>
                    </hooper>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="sp-content">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card" style="margin-bottom: 20px">
                        <div style="margin: 20px">
                          <h5>
                            <a
                              style="color: black; pointer-events: none"
                              href="javascript:"
                              @click.prevent="false"
                            >
                              {{ auctionifos.manYear
                              }}{{ auctionifos.brandName }}
                              {{ auctionifos.modelName }}
                            </a>
                          </h5>
                          <div class="tablerounededCorner">
                            <table class="table table-striped">
                              <tr>
                                <td>Make</td>
                                <td>{{ auctionifos.brandName }}</td>
                              </tr>
                              <tr>
                                <td>Model</td>
                                <td>{{ auctionifos.modelName }}</td>
                              </tr>
                              <tr>
                                <td>Registration</td>
                                <td>
                                  {{ auctionifos.vehRegNo }}
                                </td>
                              </tr>

                              <tr>
                                <td>Body Type</td>
                                <td>{{ auctionifos.vehTypeName }}</td>
                              </tr>
                              <tr>
                                <td>Location</td>
                                <td>{{ auctionifos.locName }}</td>
                              </tr>
                              <tr>
                                <td>KMs Driven</td>
                                <td>{{ auctionifos.kmClocked }} KM</td>
                              </tr>
                              <tr>
                                <td>Year Of Make</td>
                                <td>{{ auctionifos.manYear }}</td>
                              </tr>
                              <tr>
                                <td>Ownership</td>
                                <td>{{ auctionifos.ownership }}</td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>{{ auctionifos.fuelTypeName }}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" style="">
                      <div class="card">
                        <div style="margin: 20px">
                          <h6>Seller Details</h6>
                          <div class="bid-info">
                            <div class="bid-info-left">Current Bid Amount</div>
                            <div
                              class="bid-info-right"
                              v-if="auctionifos.latestBidAmount == null"
                            >
                              ₹ 0
                            </div>
                            <div class="bid-info-right" v-else>
                              {{ auctionifos.latestBidAmount | currency }}
                            </div>
                          </div>
                          <div class="bid-info">
                            <div class="bid-info-left">Name</div>
                            <div class="bid-info-right">
                              {{ auctionifos.sellerName }}
                            </div>
                          </div>
                          <div v-if="auctionifos.paymentStatus == true">
                            <div class="bid-info">
                              <div class="bid-info-left">Phone</div>
                              <div class="bid-info-right">
                                <a href="tel:+">{{
                                  auctionifos.sellerPhone
                                }}</a>
                              </div>
                            </div>
                            <div class="bid-info">
                              <div class="bid-info-left">Address</div>
                              <div
                                class="bid-info-right"
                                v-if="auctionifos.sellerAddress"
                              >
                                {{ auctionifos.sellerAddress }}
                              </div>
                              <div
                                class="bid-info-right"
                                v-if="!auctionifos.sellerAddress"
                              >
                                Not Available
                              </div>
                            </div>
                            <div
                              v-if="
                                auctionifos.aucWonOtp &&
                                auctionifos.otpStatus != true
                              "
                            >
                              <p
                                class="alert alert-warning"
                                role="alert"
                                style="font-weight: bold"
                              >
                                "Please provide this OTP to the vendor in person
                                after meeting with them to confirm the vehicle.
                                Do not share it over the phone."
                              </p>
                              <button
                                class="btn btn-primary detailsBtn"
                                disabled
                              >
                                Otp - {{ auctionifos.aucWonOtp }}
                              </button>
                            </div>
                            <div v-else-if="auctionifos.otpStatus == true">
                              <button
                                class="btn btn-success"
                                style="width: 100%"
                                disabled
                              >
                                <i class="fa fa-check-square"></i>
                                Verified
                              </button>
                            </div>
                            <div v-else>
                              <button
                                :disabled="loading"
                                class="btn btn-primary detailsBtn"
                                @click="generateOtp(auctionifos)"
                              >
                                <span
                                  v-show="loading"
                                  class="spinner-border spinner-border-sm"
                                ></span>
                                <span>Generate OTP</span>
                              </button>
                            </div>
                          </div>
                          <div v-else>
                            <p
                              class="alert alert-warning"
                              role="alert"
                              style="font-weight: bold"
                            >
                              ! Please pay the token amount to view seller
                              details.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section style="width: 100%">
                <div
                  class="card"
                  style="
                    margin-right: 30px;
                    margin-left: 30px;
                    margin-bottom: 20px;
                  "
                >
                  <div style="padding: 20px">
                    <h4>Vehicle Description</h4>
                    <div v-if="auctionifos.vehdescription != null">
                      <p>{{ auctionifos.vehdescription }}.</p>
                    </div>
                  </div>
                  <div style="padding: 0px 20px">
                    <!-- Tab links -->
                    <div class="tab">
                      <button
                        class="tablinks"
                        @click="openCity(event, 'options')"
                      >
                        Options and Features
                      </button>

                      <button
                        class="tablinks"
                        @click="openCity(event, 'technical')"
                      >
                        Technical Specification
                      </button>
                    </div>

                    <!-- Tab content -->
                    <div id="options" class="tabcontent">
                      <table class="table">
                        <tr>
                          <td data-toggle="collapse" data-target="#demo">
                            Roof AC
                          </td>
                          <td v-if="auctionifos.adjustableSteering == true">
                            YES
                          </td>
                          <td
                            v-else-if="auctionifos.adjustableSteering == false"
                          >
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Alloy Wheels</td>
                          <td v-if="auctionifos.alloyWheels == true">YES</td>
                          <td v-else-if="auctionifos.alloyWheels == false">
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Anti Theft Device</td>
                          <td v-if="auctionifos.antiTheftDevice == true">
                            YES
                          </td>
                          <td v-else-if="auctionifos.antiTheftDevice == false">
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Aux Compatibility</td>
                          <td v-if="auctionifos.auxCompatibility == true">
                            YES
                          </td>
                          <td v-else-if="auctionifos.auxCompatibility == false">
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Rear Wiper</td>
                          <td v-if="auctionifos.bluetooth == true">YES</td>
                          <td v-else-if="auctionifos.bluetooth == false">NO</td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Comprehensive Navigation System</td>
                          <td v-if="auctionifos.navigationSystem == true">
                            YES
                          </td>
                          <td v-else-if="auctionifos.navigationSystem == false">
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Parking Sensors</td>
                          <td v-if="auctionifos.parkingSensors == true">YES</td>
                          <td v-else-if="auctionifos.parkingSensors == false">
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Power steering</td>
                          <td v-if="auctionifos.powerSteering == true">YES</td>
                          <td v-else-if="auctionifos.powerSteering == false">
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Music System</td>
                          <td v-if="auctionifos.FMRadio == true">YES</td>
                          <td v-else-if="auctionifos.FMRadio == false">NO</td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Service History</td>
                          <td v-if="auctionifos.serviceHistory == true">YES</td>
                          <td v-else-if="auctionifos.serviceHistory == false">
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Rear Parking Camera</td>
                          <td v-if="auctionifos.rearParkingCamera == true">
                            YES
                          </td>
                          <td
                            v-else-if="auctionifos.rearParkingCamera == false"
                          >
                            NO
                          </td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Sunroof</td>
                          <td v-if="auctionifos.sunroof == true">YES</td>
                          <td v-else-if="auctionifos.sunroof == false">NO</td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Cruise Control</td>
                          <td v-if="auctionifos.usb == true">YES</td>
                          <td v-else-if="auctionifos.usb == false">NO</td>
                          <td v-else>NA</td>
                        </tr>
                      </table>
                    </div>

                    <div
                      id="technical"
                      class="tabcontent"
                      style="display: none"
                    >
                      <table class="table table-striped">
                        <tr>
                          <td>ABS</td>
                          <td v-if="auctionifos.abs == true">YES</td>
                          <td v-else-if="auctionifos.abs == false">NO</td>
                          <td v-else>NA</td>
                        </tr>
                        <tr>
                          <td>Transmission</td>
                          <td v-if="auctionifos.HP">{{ auctionifos.HP }}</td>
                          <td v-else>NA</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <b-modal
            id="modalbidd"
            ref="modal"
            @hidden="resetModal"
            hide-footer
            v-if="userinfo"
          >
            <form ref="form" v-if="issuccess != 'Success'">
              <div
                v-if="
                  selectedUser.latestBidAmount == null ||
                  selectedUser.latestBidAmount == ''
                "
              >
                <p for="bidamount" class="bidamountzz" style="color: red">
                  Under dev
                </p>

                <div
                  class="bidsentry"
                  style="
                    display: inline-flex;
                    flex-direction: row;
                    align-items: baseline;
                  "
                ></div>
              </div>
              <div v-else>
                <p for="bidamount" class="bidamountzz" style="color: red">
                  Under dev
                </p>

                <div
                  class="bidsentry"
                  style="
                    display: inline-flex;
                    flex-direction: row;
                    align-items: baseline;
                  "
                ></div>
              </div>
            </form>
            <form v-else-if="issuccess == 'Success'">
              <successMessage></successMessage>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from "../config";
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper";
import "hooper/dist/hooper.css";
import userService from "../services/user.service";
import userinformation from "../models/localstorage";
import moment from "moment";

let imag1 = "";
let imag2 = "";
let imag3 = "";
let imag4 = "";
export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },

  data() {
    return {
      CurrNttimezone: Config.CurrNttimezone,
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      imag1: imag1,
      imag2: imag2,
      imag3: imag3,
      imag4: imag4,
      details: "",
      auctionifos: "",
      bid: "",
      message: "",
      selectedUser: "",
      userinfo: "",
      isBitEntryModelActive: false,
      timeIntervel: Config.RefreshAuctionPage,
      vehImageArry: [],
      path: Config.BASE_URL_IMG,
      isLoading: false,
      issuccess: "",
      initialLoading: true,
      minimumBidAmount: null,
      otpGen: "",
      loading: false,
    };
  },
  created() {
    this.userinfo = this.currentUser;
    this.timer = setInterval(this.refreshAuctionList, this.timeIntervel);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  filters: {
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm a");
      }
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
    dateISO(value) {
      if (value) {
        var d = new Date(value);
        // d = d.toLocaleString("en-US", { timeZone: Config.CurrNttimezone });
        // var date = new Date(d);
        return d.toISOString();
      }
    },
  },
  mounted() {
    let id = this.$route.params.url_key;

    this.getwonAuctionDetailsbyId(id);
  },
  props: ["changed_url"],
  watch: {
    "$route.query.search"() {
      let id = this.$route.query.search;
      this.getwonAuctionDetailsbyId(id);
    },
  },
  methods: {
    gotoPreviousPage() {
      const returnUrl = sessionStorage.getItem("returnUrl");
      if (returnUrl) {
        window.location = returnUrl;
      }
    },
    goBack() {
      history.back();
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
    generateOtp(auctionifos) {
      this.loading = true;
      let cusid = userinformation[0].customerId;
      userService
        .generateWonOtp(auctionifos, cusid)
        .then((response) => {
          // console.log(response.data.Message);
          if (response.data.Message == "Success") {
            this.loading = false;
            this.makeToast("success", "Otp generated successfully");
          }
          setTimeout(() => {
            this.gotoPreviousPage();
          }, 2000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.loading = false;
          let resMessage =
            (error.response && error.response.data.Message) ||
            error.message ||
            error.toString();
          this.makeToast("danger", resMessage);
        });
    },
    refreshAuctionList() {
      let id = this.$route.params.url_key;
      if (this.isBitEntryModelActive == false) {
        this.getwonAuctionDetailsbyId(id);
      }
    },
    closeModal() {
      this.isBitEntryModelActive = false;
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 1000);
    },
    hideModal() {
      this.issuccess = "";
      this.$refs["modal"].hide();
    },
    resetModal() {
      this.message = "";
      this.bid = "";
      this.message = null;
      this.bid = null;
      this.isBitEntryModelActive = false;
      this.issuccess = "";
      this.selectedUser.aucMinBid = this.minimumBidAmount;
    },
    gotologin() {
      localStorage.setItem(
        "guestid",
        "_" + Math.random().toString(36).substr(2, 9)
      );
      this.$router.push("/").catch(() => {
        "login";
      });
    },
    sendInfo(auctionifos) {
      this.isBitEntryModelActive = true;
      if (!this.userinfo) {
        this.gotologin();
      } else {
        this.selectedUser = auctionifos;
        this.minimumBidAmount = auctionifos.aucMinBid;
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    getwonAuctionDetailsbyId(id) {
      if (!id || id == "") {
        this.$router.push("/MyWinnings").catch(() => {
          // this.$router.push("/Auction").catch(() => {
          "details/auctionbyname";
        });
      } else {
        userService
          .getwonAuctionDetailsbyId(id)
          .then((response) => {
            this.auctionifos = response.data.Data[0];
            this.initialLoading = false;
            if (this.auctionifos == null) {
              this.$router.push("/MyWinnings").catch(() => {
                // this.$router.push("/Auction").catch(() => {
                "details/auctionbyname";
              });
            }
            let image1 = null;
            let image2 = null;
            let image3 = null;
            let image4 = null;
            let image5 = null;
            let image6 = null;
            let image7 = null;
            let image8 = null;
            let image9 = null;
            let image10 = null;
            let image11 = null;
            let image12 = null;
            let image13 = null;
            let image14 = null;
            let image15 = null;

            if (typeof this.auctionifos.vehImage1 != "undefined") {
              image1 = this.auctionifos.vehImage1;
            } //else{image1  = ""};
            if (typeof this.auctionifos.vehImage2 != "undefined") {
              image2 = this.auctionifos.vehImage2;
            } //else{image2  = ""};
            if (typeof this.auctionifos.vehImage3 != "undefined") {
              image3 = this.auctionifos.vehImage3;
            } //else{image3  = ""};
            if (typeof this.auctionifos.vehImage4 != "undefined") {
              image4 = this.auctionifos.vehImage4;
            } //else{image4  = ""};
            if (typeof this.auctionifos.vehImage5 != "undefined") {
              image5 = this.auctionifos.vehImage5;
            } //else{image5  = ""};
            if (typeof this.auctionifos.vehImage6 != "undefined") {
              image6 = this.auctionifos.vehImage6;
            } //else{image6  = ""};
            if (typeof this.auctionifos.vehImage7 != "undefined") {
              image7 = this.auctionifos.vehImage7;
            } //else{image7  = ""};
            if (typeof this.auctionifos.vehImage8 != "undefined") {
              image8 = this.auctionifos.vehImage8;
            } //else{image8  = ""};
            if (typeof this.auctionifos.vehImage9 != "undefined") {
              image9 = this.auctionifos.vehImage9;
            } //else{image9  = ""};
            if (typeof this.auctionifos.vehImage10 != "undefined") {
              image10 = this.auctionifos.vehImage10;
            } //else{image10 = ""};
            if (typeof this.auctionifos.vehImage11 != "undefined") {
              image11 = this.auctionifos.vehImage11;
            } //else{image11 = ""};
            if (typeof this.auctionifos.vehImage12 != "undefined") {
              image12 = this.auctionifos.vehImage12;
            } //else{image12 = ""};
            if (typeof this.auctionifos.vehImage13 != "undefined") {
              image13 = this.auctionifos.vehImage13;
            } //else{image13 = ""};
            if (typeof this.auctionifos.vehImage14 != "undefined") {
              image14 = this.auctionifos.vehImage14;
            } //else{image14 = ""};
            if (typeof this.auctionifos.vehImage15 != "undefined") {
              image15 = this.auctionifos.vehImage15;
            } //else{image15 = ""};

            // console.log(this.auctionifos);
            let imageArry = [
              { imageurl: image1 },
              { imageurl: image2 },
              { imageurl: image3 },
              { imageurl: image4 },
              { imageurl: image5 },
              { imageurl: image6 },
              { imageurl: image7 },
              { imageurl: image8 },
              { imageurl: image9 },
              { imageurl: image10 },
              { imageurl: image11 },
              { imageurl: image12 },
              { imageurl: image13 },
              { imageurl: image14 },
              { imageurl: image15 },
            ];

            this.vehImageArry = imageArry.filter(function (el) {
              return el.imageurl != null;
            });
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
            this.$router.push("/MyWinnings").catch(() => {
              // this.$router.push("/Auction").catch(() => {
              "details/auctionbyname";
            });
          });
      }
    },

    showMultiple(images, index) {
      let element = [];

      for (let i = 0; i < images.length; i++) {
        element.push(this.path + images[i].imageurl);
      }

      this.imgs = element;
      this.index = index;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    openCity(evt, cityName) {
      // Declare all variables
      var i, tabcontent, tablinks;

      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      // Show the current tab, and add an "active" class to the button that opened the tab
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    },
  },

  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
<style scoped>
.digit {
  padding-left: 0px !important;
}

.thumbnaildetails img {
  height: 80px;
  width: 110px;
  object-fit: cover;
}
.hooper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.hooper-slide {
  flex-shrink: 0;

  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  object-fit: cover;
}
.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/* Style the tab */
.tab {
  overflow: hidden;
  /* border: 1px solid #ccc;
  background-color: #f1f1f1; */
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: whitesmoke;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  border-radius: 25px;
  margin: 4px;

  /* background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s; */
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
/* .tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
} */
/* .collapsible {
  background-color: rgb(233, 225, 225);
  color: rgb(20, 15, 15);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-radius: 5px;
}

.active, .collapsible:hover {
  background-color: #555;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;

} */
</style>
<style>
.countdown {
  display: flex;
}
.countdown :nth-child(2) {
  margin-left: 4px;
}
</style>
